<template>
    <div class="platform-bottom-layout">
        <component :is="currentComponent + 'Component'" ref="right" :showAllTask.sync="showAllTask" :id.sync="taskId" />
    </div>
</template>

<script>
export default {
  props: {
    taskId: Number,
    taskBottomtype: Number,
    showAllTask: Boolean
  },
  components: {
    summaryComponent: (resolve) => {
      require(['./right/Summary'], resolve)
    },
    invoiceComponent: (resolve) => {
      require(['./right/Invoice'], resolve)
    }
  },
  data () {
    return {
      currentComponent: 'list'
    }
  },
  created () {
    this.initComponent()
  },
  methods: {
    reload () {
      this.$refs.right.reload()
    },
    initComponent () {
      if (this.taskBottomtype === 1) {
        this.currentComponent = 'summary'
      } else if (this.taskBottomtype === 2) {
        this.currentComponent = 'invoice'
      }
    }
  },
  watch: {
    taskBottomtype () {
      this.initComponent()
    }
  }
}
</script>
